import React, { useRef, useState, useEffect } from 'react'
import Axios from 'axios'
import QRCode from 'qrcode.react'
import styled from 'styled-components'

import {
  InputBox,
  ModalLabel,
  InputFieldModal,
  FormContainer,
  Form,
  DataValue,
  BtnLarge,
} from './CommonStyles'

const FormWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  background: white;
`
const HeaderVerify = styled.div`
  color: ${(props) => props.theme.primary_color};
  font-size: 30px;
`
const QR = styled(QRCode)`
  display: block;
  margin: auto;
  padding: 20px;
  background: #fff;
`

function DataForm() {
  const [invitation, setInvitation] = useState('')
  const [verificationComplete, setVerificationComplete] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [verification, setVerification] = useState({})
  const [errMessage, setErrMessage] = useState('')
  const [toggleForm, setToggleForm] = useState(false)
  const [hotelDetails, sethotelDetails] = useState({})
  const [verifiedImage, setVerifiedImage] = useState('')

  const effectRan = useRef()
  const newForm = useRef()

  // Invitation
  useEffect(() => {
    // (mikekebert) Prevent double triggering. See React strict mode.
    //if (effectRan.current === true) 
    {
      Axios({
        method: 'POST',
        url: `/api/invitations`,
      })
        .then((res) => {
          console.log('Invitation:', res.data)

          setInvitation(res.data)
        })
        .catch((err) => {
          console.log('Error: ', err)
          if (err.response.data.message) {
            setErrMessage(err.response.data.message)
          }
        })
    }

    return () => {
    //  effectRan.current = true
    }
  }, [])

  // Verification
  useEffect(() => {
    if (invitation.invitation_id && !verificationComplete) {
      Axios({
        method: 'POST',
        url: `/api/verifications`,
        data: {
          connection_id: invitation.connection_id,
          contact_id: invitation.contact_id,
          invitation_id: invitation.invitation_id,
        },
        timeout: 1000 * 60 * 35,
      })
        .then((verRes) => {
          const verificationRecords = verRes.data.verificationRecords
          setVerificationComplete(true)
  
          console.log('Verification:', verRes)
          
          let verifiedAttributes = {}
          verificationRecords.forEach((record) => {
            record.result_data.forEach((attributes, index) => {
              verifiedAttributes[attributes.name] = attributes.value
            })
          })

          //(AmmonBurgi) If needed, format the base64 to be compatible with an html img element
          if (verifiedAttributes['chip-photo']) {
            const imageParts = verifiedAttributes['chip-photo'].split(',')
            if (imageParts[0] !== 'data:image/jpeg;base64') {
              setVerifiedImage(
                'data:image/jpeg;base64,' + verifiedAttributes['chip-photo']
              )
            } else {
              setVerifiedImage(verifiedAttributes['chip-photo'])
            }
          }

          setVerification({
            connectionId: verificationRecords[0].connection_id,
            verifiedAttributes: verifiedAttributes,
          })
        })
        .catch((err) => {
          console.error('Error: ', err)
          if (err.response.data.message) {
            setErrMessage(err.response.data.message)
          }
        })
    }
  }, [invitation, verificationComplete])

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = new FormData(newForm.current)
    const formData = {
      traveler_phone: form.get('traveler_phone'),
      traveler_email: form.get('traveler_email'),
      traveler_country: form.get('traveler_country'),
      traveler_country_of_origin: form.get('traveler_country_of_origin'),
      arrival_airline: form.get('arrival_airline'),
      arrival_flight_number: form.get('arrival_flight_number'),
      arrival_date: form.get('arrival_date'),
      arrival_destination_port_code: form.get('arrival_destination_port_code'),
      arrival_destination_country_code: form.get(
        'arrival_destination_country_code'
      ),
      departure_airline: form.get('departure_airline'),
      departure_flight_number: form.get('departure_flight_number'),
      departure_date: form.get('departure_date'),
      departure_destination_port_code: form.get(
        'departure_destination_port_code'
      ),
      departure_destination_country_code: form.get(
        'departure_destination_country_code'
      ),
    }

    console.log(verification.connectionId)

    //     Axios({
    //       method: 'POST',
    //       url: `/api/credentials/`,
    //       data: {
    //         travelerData: formData,
    //         dtcData: verification.verifiedAttributes,
    //         connectionId: verification.connectionId
    //       }
    //     }).then(() => {
    setFormSubmitted(true)
    //     }).catch(err => {
    //       console.error('Error: ', err)
    //       if (err.response.data.message) {
    //         setErrMessage(err.response.data.message)
    //       }
    //     })
  }

  const selectOption = (option) => {
    switch (option) {
      case 'option_one':
        console.log(option)
        sethotelDetails({
          one_title: 'Mr',
          one_address: '1234 Woodscross Way',
          one_city: 'Scranton',
          one_postal_code: '85921',
          one_state: 'Pennsylvania',
          one_place_of_birth: 'Los Angeles',
          one_email: 'mike_scarn@dunder.com',
          one_phone_number: '801-553-1233',
          one_anniversary_date: '1999-01-08',
          one_occasion: 'Vacation',
          one_guests: '1',
          hotel_repeat_guest: 'No',
          hotel_check_in_date: '2022-09-25',
          hotel_check_out_date: '2022-09-30',
          hotel_arrival_date: '2022-09-25',
          hotel_arrival_flight: 'Delta',
          hotel_departure_date: '2022-09-30',
          hotel_departure_flight: 'American',
          hotel_notes: 'No notes as of now',
          card_name: 'Michael Scott',
          card_number: '889182048912',
          card_expiration_date: '2028-04-15',
          card_security_code: '781',
          card_postal_code: '84211',
        })

        break

      case 'option_two':
        console.log(option)
        sethotelDetails({
          one_title: 'Mr',
          one_address: '321 Forest Drive',
          one_city: 'Provo',
          one_postal_code: '11421',
          one_state: 'Utah',
          one_place_of_birth: 'USA',
          one_email: 'hello@gmail.com',
          one_phone_number: '801-995-2230',
          one_anniversary_date: '1982-09-04',
          one_occasion: 'Work',
          one_guests: '3',
          hotel_repeat_guest: 'No',
          hotel_check_in_date: '2022-09-26',
          hotel_check_out_date: '2022-09-28',
          hotel_arrival_date: '2022-09-26',
          hotel_arrival_flight: 'Spirit',
          hotel_departure_date: '2022-09-28',
          hotel_departure_flight: 'Singapore',
          hotel_notes: 'Customer visits often',
          card_name: 'John Smith',
          card_number: '184242131234',
          card_expiration_date: '2026-12-12',
          card_security_code: '445',
          card_postal_code: '84601',
        })

        break

      default:
        sethotelDetails({
          one_title: '',
          one_address: '',
          one_city: '',
          one_postal_code: '',
          one_state: '',
          one_place_of_birth: '',
          one_email: '',
          one_phone_number: '',
          one_anniversary_date: '',
          one_occasion: '',
          one_guests: '',
          hotel_repeat_guest: '',
          hotel_check_in_date: '',
          hotel_check_out_date: '',
          hotel_arrival_date: '',
          hotel_arrival_flight: '',
          hotel_departure_date: '',
          hotel_departure_flight: '',
          hotel_notes: '',
          card_name: '',
          card_number: '',
          card_expiration_date: '',
          card_security_code: '',
          card_postal_code: '',
        })
        break
    }
  }

  let attributes = verification.verifiedAttributes
    ? verification.verifiedAttributes
    : {}

  const invitationDisplay = (
    <div>
      <div className="introduction-row">
        <h1>
            Welcome to Divi Tamarijn's
            Advanced Hotel&nbsp;Check In
        </h1>
        <p>
          Enjoy a seamless hotel check in that speeds up your arrival process
          meaning less time at the front desk and more time by the&nbsp;pool
        </p>
      </div>
      <div className="content-row">
        <p>Using the mobile app, open the camera and scan the QR code presented here:</p>
        <p>
          <QR value={invitation.invitation_url} size={350} renderAs="svg" />
        </p>
        {/* <p className="info-text">
          <strong>Download and Passport Scan: </strong>
          Travelers must have already downloaded the traveler mobile application
          onto their mobile device and received a DTC passport&nbsp;credential
        </p> */}
      </div>
    </div>
  )

  const passportDisplay = (
    <div>
      {console.log(verification.verifiedAttributes)}
      <div className="introduction-row">
        <h1 className="text-center-vert">
          Submit Passport Details
        </h1>
      </div>
      <div className="content-row">
        <p className="content-text">
          Thank you for sharing your DTC credential with us. Here is the
          information we received.
        </p>
      
        <div className="passport-div">
        {verifiedImage ? (
          <img className="chip-photo" src={verifiedImage} alt="Passport" />
          ) : null}
          <span className="passport-type">
            {attributes['document-type'] ? attributes['document-type'] : ''}
          </span>
          <span className="passport-country-code">
            ATL
          </span>
          <span className="passport-number">
            {attributes['document-number']
              ? attributes['document-number']
              : ''}
          </span>
          <span className="passport-surname">
            {attributes['family-name'] ? attributes['family-name'] : ''}
          </span>
          <span className="passport-given-names">
            {attributes['given-names'] ? attributes['given-names'] : ''}
          </span>
          <span className="passport-nationality">
            {attributes.nationality ? attributes.nationality : ''}
          </span>
          <span className="passport-birth-date">
            {attributes['date-of-birth'] ? attributes['date-of-birth'] : ''}
          </span>
          <span className="passport-sex">
            {attributes.gender ? attributes.gender : ''}
          </span>
          <span className="passport-birth-place">
            Azimuth
          </span>
          <span className="passport-issue-date">
            {attributes['issue-date'] ? attributes['issue-date'] : ''}
          </span>
          <span className="passport-expiry-date">
            {attributes['expiry-date'] ? attributes['expiry-date'] : ''}
          </span>
          <span className="passport-authority">
            {attributes['issuing-authority']
              ? attributes['issuing-authority']
              : ''}
          </span>
        </div>

        <p className="spacing text-center">
          <BtnLarge onClick={() => setToggleForm(true)}>Continue →</BtnLarge>
        </p>

        <p className="info-text text-center">
          <strong>Review: </strong>
          Travelers receive confirmation that their DTC credential details were
          successfully received by the system
        </p>
      </div>
    </div>
  )

  const formDisplay = (
    <div>
      <div className="introduction-row">
        <h1 className="text-center-vert">
          Hotel Stay Details
        </h1>
      </div>
      <div className="content-row content-text">
        <p>Input your reservation information and tap Submit to proceed</p>

        <FormContainer>
          <FormWrapper>
            <HeaderVerify>Passport Details</HeaderVerify>
          </FormWrapper>
          <Form>
            <InputBox>
              <ModalLabel>Type</ModalLabel>
              <DataValue>
                {attributes['document-type']
                  ? attributes['document-type']
                  : ''}
              </DataValue>
            </InputBox>
            <InputBox>
              <ModalLabel>Country Code</ModalLabel>
              <DataValue>ATL</DataValue>
            </InputBox>
            <InputBox>
              <ModalLabel>Passport Number</ModalLabel>
              <DataValue>
                {attributes['document-number']
                  ? attributes['document-number']
                  : ''}
              </DataValue>
            </InputBox>
            <InputBox>
              <ModalLabel>Surname</ModalLabel>
              <DataValue>
                {attributes['family-name'] ? attributes['family-name'] : ''}
              </DataValue>
            </InputBox>
            <InputBox>
              <ModalLabel>Given Names</ModalLabel>
              <DataValue>
                {attributes['given-names'] ? attributes['given-names'] : ''}
              </DataValue>
            </InputBox>
            <InputBox>
              <ModalLabel>Nationality</ModalLabel>
              <DataValue>
                {attributes.nationality ? attributes.nationality : ''}
              </DataValue>
            </InputBox>
            <InputBox>
              <ModalLabel>Date of Birth</ModalLabel>
              <DataValue>
                {attributes['date-of-birth']
                  ? attributes['date-of-birth']
                  : ''}
              </DataValue>
            </InputBox>
            <InputBox>
              <ModalLabel>Personal Number</ModalLabel>
              <DataValue>z e 184226 b</DataValue>
            </InputBox>
            <InputBox>
              <ModalLabel>Sex</ModalLabel>
              <DataValue>
                {attributes.gender ? attributes.gender : ''}
              </DataValue>
            </InputBox>
            <InputBox>
              <ModalLabel>Place of Birth</ModalLabel>
              <DataValue>Zenith</DataValue>
            </InputBox>
            <InputBox>
              <ModalLabel>Issue Date</ModalLabel>
              <DataValue>
                {attributes['issue-date'] ? attributes['issue-date'] : ''}
              </DataValue>
            </InputBox>
            <InputBox>
              <ModalLabel>Authority</ModalLabel>
              <DataValue>
                {attributes['issuing-authority']
                  ? attributes['issuing-authority']
                  : ''}
              </DataValue>
            </InputBox>
            <InputBox>
              <ModalLabel>Expiry Date</ModalLabel>
              <DataValue>
                {attributes['expiry-date'] ? attributes['expiry-date'] : ''}
              </DataValue>
            </InputBox>
            <InputBox>
              <ModalLabel>Signature</ModalLabel>
              <DataValue>Signature</DataValue>
            </InputBox>
          </Form>
        </FormContainer>

        <Form id="form" onSubmit={handleSubmit} ref={newForm}>
          <InputBox>
            <ModalLabel>Hotel Details Sample Sets</ModalLabel>
            <select
              onChange={(e) => selectOption(e.target.value)}
              className="dropdown-content"
            >
              <option value="default">Select Option:</option>
              <option value="option_one">Option 1</option>
              <option value="option_two">Option 2</option>
            </select>
          </InputBox>
          <InputBox>
            <ModalLabel>Accompanying # of Guests</ModalLabel>
            <select 
            name="one_guests"
            defaultValue={hotelDetails.one_guests}
            >
              <option value='default'></option>
              <option value='one'>1</option>
              <option value='two'>2</option>
              <option value='three'>3</option>
              <option value='four'>4+</option>
            </select>
          </InputBox>
          <FormContainer>
            <FormWrapper>
              <HeaderVerify>Guest 1</HeaderVerify>
            </FormWrapper>
            <InputBox>
              <ModalLabel htmlFor="one_title">Title</ModalLabel>
              <InputFieldModal
                type="text"
                name="one_title"
                defaultValue={hotelDetails.one_title}
              ></InputFieldModal>
            </InputBox>

            <InputBox>
              <ModalLabel>Last Name</ModalLabel>
              <DataValue>
                {attributes['family-name'] ? attributes['family-name'] : ''}
              </DataValue>
            </InputBox>

            <InputBox>
              <ModalLabel htmlFor="one_first_name">First Name</ModalLabel>
              <DataValue>
                {attributes['given-names'] ? attributes['given-names'] : ''}
              </DataValue>
            </InputBox>

            <InputBox>
              <ModalLabel htmlFor="one_address">Address</ModalLabel>
              <InputFieldModal
                type="text"
                name="one_address"
                defaultValue={hotelDetails.one_address}
              ></InputFieldModal>
            </InputBox>

            <InputBox>
              <ModalLabel htmlFor="one_city">City</ModalLabel>
              <InputFieldModal
                type="text"
                name="one_city"
                defaultValue={hotelDetails.one_city}
              ></InputFieldModal>
            </InputBox>

            <InputBox>
              <ModalLabel htmlFor="one_postal_code">Postal Code</ModalLabel>
              <InputFieldModal
                type="text"
                name="one_postal_code"
                defaultValue={hotelDetails.one_postal_code}
              ></InputFieldModal>
            </InputBox>

            <InputBox>
              <ModalLabel htmlFor="one_state">State</ModalLabel>
              <InputFieldModal
                type="text"
                name="one_state"
                defaultValue={hotelDetails.one_state}
              ></InputFieldModal>
            </InputBox>

            <InputBox>
              <ModalLabel htmlFor="one_country">Country</ModalLabel>
              <DataValue>
                {attributes.nationality ? attributes.nationality : ''}
              </DataValue>
            </InputBox>

            <InputBox>
              <ModalLabel>Date of Birth</ModalLabel>
              <DataValue>
                {attributes['date-of-birth']
                  ? attributes['date-of-birth']
                  : ''}
              </DataValue>
            </InputBox>

            <InputBox>
              <ModalLabel>Place of Birth</ModalLabel>
              <InputFieldModal
                type="text"
                name="one_place_of_birth"
                defaultValue={hotelDetails.one_place_of_birth}
              ></InputFieldModal>
            </InputBox>

            <InputBox>
              <ModalLabel htmlFor="one_email">Email</ModalLabel>
              <InputFieldModal
                type="text"
                name="one_email"
                defaultValue={hotelDetails.one_email}
              ></InputFieldModal>
            </InputBox>

            <InputBox>
              <ModalLabel htmlFor="one_phone_number">Phone Number</ModalLabel>
              <InputFieldModal
                type="text"
                name="one_phone_number"
                defaultValue={hotelDetails.one_phone_number}
              ></InputFieldModal>
            </InputBox>

            <InputBox>
              <ModalLabel htmlFor="one_departure_date">
                Anniversary Date
              </ModalLabel>
              <InputFieldModal
                type="date"
                name="one_departure_date"
                defaultValue={hotelDetails.one_anniversary_date}
              ></InputFieldModal>
            </InputBox>

            <InputBox>
              <ModalLabel htmlFor="traveler_email">Occasion</ModalLabel>
              <InputFieldModal
                type="text"
                name="one_occasion"
                defaultValue={hotelDetails.one_occasion}
              ></InputFieldModal>
            </InputBox>

            <FormWrapper>
              <HeaderVerify>Hotel Details</HeaderVerify>
            </FormWrapper>

            <InputBox>
              <ModalLabel htmlFor="departure_airline">
                Repeat Guest
              </ModalLabel>
              <InputFieldModal
                type="text"
                name="hotel_repeat_guest"
                defaultValue={hotelDetails.hotel_repeat_guest}
              ></InputFieldModal>
            </InputBox>
            <InputBox>
              <ModalLabel htmlFor="departure_flight_number">
                Check-In Date
              </ModalLabel>
              <InputFieldModal
                type="date"
                name="hotel_check_in_date"
                defaultValue={hotelDetails.hotel_check_in_date}
              ></InputFieldModal>
            </InputBox>
            <InputBox>
              <ModalLabel htmlFor="departure_date">Check-Out Date</ModalLabel>
              <InputFieldModal
                type="date"
                name="hotel_check_out_date"
                defaultValue={hotelDetails.hotel_check_out_date}
              ></InputFieldModal>
            </InputBox>
            <InputBox>
              <ModalLabel htmlFor="departure_destination_port_code">
                Arrival Date
              </ModalLabel>
              <InputFieldModal
                type="date"
                name="hotel_arrival_date"
                defaultValue={hotelDetails.hotel_arrival_date}
              ></InputFieldModal>
            </InputBox>
            <InputBox>
              <ModalLabel htmlFor="departure_destination_country_code">
                Arrival Flight
              </ModalLabel>
              <InputFieldModal
                type="text"
                name="hotel_arrival_flight"
                defaultValue={hotelDetails.hotel_arrival_flight}
              ></InputFieldModal>
            </InputBox>
            <InputBox>
              <ModalLabel htmlFor="departure_destination_country_code">
                Departure Date
              </ModalLabel>
              <InputFieldModal
                type="date"
                name="hotel_departure_date"
                defaultValue={hotelDetails.hotel_departure_date}
              ></InputFieldModal>
            </InputBox>
            <InputBox>
              <ModalLabel htmlFor="departure_destination_country_code">
                Departure Flight
              </ModalLabel>
              <InputFieldModal
                type="text"
                name="hotel_departure_flight"
                defaultValue={hotelDetails.hotel_departure_flight}
              ></InputFieldModal>
            </InputBox>
            <InputBox>
              <ModalLabel htmlFor="departure_destination_country_code">
                Notes
              </ModalLabel>
              <InputFieldModal
                type="text"
                name="hotel_notes"
                defaultValue={hotelDetails.hotel_notes}
              ></InputFieldModal>
            </InputBox>

            <FormWrapper>
              <HeaderVerify>Card Information</HeaderVerify>
            </FormWrapper>

            <InputBox>
              <ModalLabel htmlFor="card_name">
                Name on Card
              </ModalLabel>
              <InputFieldModal
                type="text"
                name="card_name"
                defaultValue={hotelDetails.card_name}
              ></InputFieldModal>
            </InputBox>
            <InputBox>
              <ModalLabel htmlFor="card_number">
                Card Number
              </ModalLabel>
              <InputFieldModal
                type="text"
                name="card_number"
                defaultValue={hotelDetails.card_number}
              ></InputFieldModal>
            </InputBox>
            <InputBox>
              <ModalLabel htmlFor="expiration_date">Expiration Date</ModalLabel>
              <InputFieldModal
                type="date"
                name="card_expiration_date"
                defaultValue={hotelDetails.card_expiration_date}
              ></InputFieldModal>
            </InputBox>
            <InputBox>
              <ModalLabel htmlFor="card_security_code">
                Security Code
              </ModalLabel>
              <InputFieldModal
                type="text"
                name="card_security_code"
                defaultValue={hotelDetails.card_security_code}
              ></InputFieldModal>
            </InputBox>
            <InputBox>
              <ModalLabel htmlFor="card_postal_code">
                Postal Code
              </ModalLabel>
              <InputFieldModal
                type="text"
                name="card_postal_code"
                defaultValue={hotelDetails.card_postal_code}
              ></InputFieldModal>
            </InputBox>
          </FormContainer>
          <p className="text-center">
            <BtnLarge type="submit">Submit →</BtnLarge>
          </p>
        </Form>

        <p className="info-text text-center">
          <strong>Provide Additional Information: </strong>
          Guests supply details about their reservation that are not contained
          in their DTC credential
        </p>
      </div>
    </div>
  )

  const successDisplay = (
    <div>
      <div className="introduction-row">
        <h1 className="text-center-vert">
          Success!
        </h1>
      </div>
      <div className="content-row">
        <p>Thank you, your reservation information was received.</p>
        <p>
          You still need to come to the front desk, but your check-in process
          will be significantly faster because we already have the information
          you submitted.
        </p>
      </div>
    </div>
  )

  const errorDisplay = (
    <div>
      <div className="introduction-row">
        <h1 className="text-center-vert">
          System Error
        </h1>
      </div>
      <div className='content-row'>
        <p>We're sorry, but there was an error with your submission.</p>
        <p>
          You can still check-in, but you will need to visit the front desk at
          the Divi Tamarijn Resort.
        </p>
      </div>
    </div>
  )

  return (
    <div>
      {
        !errMessage 
        ? invitation && invitation.invitation_url && verificationComplete
          ? !toggleForm
            ? passportDisplay
            : !formSubmitted
            ? formDisplay
            : successDisplay
          : invitationDisplay
        : errorDisplay
      }
    </div>
  )
}

export default DataForm
