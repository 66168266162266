import './App.css'
import DataForm from './UI/DataForm'

import logo from './assets/divi.png'
import poweredByLogo from './assets/powered-by.png'

import { Logo, PoweredByLogo } from './UI/CommonStyles'

function App() {
  return (
    <div id="frame">
      <div id="main">
        <div className="header">
          <Logo src={logo} alt="Logo" />
        </div>
        <DataForm />
        <div className="footer">
          <p className="text-center">
            Copyright © Divi Tamarijn 2023. All rights reserved.
          </p>
          <p className="text-center">
            <PoweredByLogo src={poweredByLogo} alt="poweredByLogo" />
          </p>
        </div>
      </div>
    </div>
  )
}

export default App

